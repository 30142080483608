.popup-agenda{
    width: 100%;
    min-height: 100%;
    background-color: rgba(0,0,0,0.7);
    position: fixed;
    top: 0;
    z-index: 9;
}

.popup-agenda.hide{
    display: none;
}

.agenda-card{
    width: 100%;
    height: 80%;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    border-radius: 30px 30px 0px 0px;
    padding: 20px 25px;
    overflow-y: auto;
}

.agenda-card-header{
    margin: 20px 0px;
    font-weight: 700;
    color: #4677ff;
    background-color: #fff; 
}

.status-dia{
    width: 15px;
    height: 15px;
    margin-right: 5px;
    border-radius: 50%;
}


.colors-info{
    width: 100%;
    font-size: 10px;
    margin: 10px 0px;
    display: flex;
    background-color: #fff;
}

.colors-info-item{
    margin: 5px;
    display: flex;
}

.colors-info-item .status-dia{
    width: 5px;
    height: 100%;
    border-radius: 0px;
}

div.d-flex{
    display: flex;
    flex-direction: row;
}