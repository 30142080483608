.content-agenda{
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    height: auto;
    /* height: 10px; */
}

.upagenda{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.content-box-header{
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
    grid-column: 1 / span 7; 
}

.content-box-header select{
    background-color: rgba(0,0,0,0);
    border: none;
    color: #001292;
    font-weight: bold;
    font-size: 13px;
    text-transform: uppercase;
    margin-left: .8rem;
    width: 8rem;
}

.calendario-header{
    width: 100%;
    display: flex;
    height: 50px;
    background-color: #b0c5ff;
    grid-column: 1 / span 7; 

}

.calendario-header .dia-semana{
    width: 14.28%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
}

/* .content-agenda .calendario-dia{
    width: 100%;
    display: flex;
} */

.content-agenda .dia-item{
    width: 100%;
    min-height: 5rem;
    padding: 5px;
    background-color: #fff;
    border: 1px solid #e1e1e1;
    /* overflow-y: auto; */
    position: relative;
}


.content-agenda .dia-item::-webkit-scrollbar {
    width: 3px;
}

.content-agenda .dia-item::-webkit-scrollbar-thumb {
    background: #4677ff; 
    border-radius: 10px;
}

.dia-item .dia-num{
    width: 100%;
    text-align: right;
    font-size: 10px;
    font-weight: bold;
    color: #333;
    position: sticky;
    z-index: 3;
    top: 0;
    background: white;
}

.vistoria-status{
    width: 100%;
    height: 15px;
    font-size: 8px;
    color: #fff;
    margin: 2px 0px;
    padding: 2px 5px;
}


.status-negado{
    background-color: #e91d0f;
}
    
.status-andamento{
    background-color: #ffb400;
}

.status-aprovado{
    background-color: #006400;
}

.status-aberto{
    background-color: #0abd69;
}

.status-vistoria{
    background-color: #4677ff;
}

.status-nothing{
    background-color: grey;
}