.container{
    width: 100%;
    height: 100vh;
    padding: 30px;
}

.container .content-header{
    width: 100%;
    height: 90px;
}

.content-header img{
    width: 150px;
}

.container .content-login{
    width: 100%;
    height: calc(100% - 90px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.content-login h1{
    color: #4677FF;
}

.content-login form{
    width: 100%;
}

.content-login form .campo-login{
    width: 100%;
    margin: 20px 0px;
}

.campo-login label{
    color: #818182;
    text-transform: capitalize;
}

.campo-login .campo-login-input{
    width: 100%;
    height: 40px;
    padding: 0px 10px;
    border: 2px solid #818182;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.campo-login-input i{
    color: #919192;
}

.campo-login-input input{
    width: 100%;
    height: 100%;
    padding: 0px 10px;
    border: 0;
    font-size: 12px;
}

.campo-login-info{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px; 
}

.campo-login-info .campo-login-info-item{
    display: flex;
    justify-content: center;
    align-items:center;
}

.campo-login-info input[type="checkbox"]{
    width: 15px;
    height: 15px;
}

.campo-login-info label{
    font-size: 14px;
    color: #919192;
}

.campo-login-info a{
    color: #919192;
    font-size: 13px;
}

.campo-login-submit{
    width: 100%;
    height: 40px;
    background-color: #4677FF;
    margin-top: 15px;
}

.campo-login-submit input[type="submit"]{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    color: #fff;
}

@media (min-width: 700px){
    .container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }

    .content-header{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .content-header img{
        width: 200px;
    }

    .container .content-login{
        align-items: center;
        height: auto;
        margin-top: 50px;
    }

    .content-login h1{
        width: 500px;

    }
    
    .content-login form{
       width: 500px;
    }

    .campo-login .campo-login-input{
        height: 50px;
        padding: 0px 20px;
    }
}