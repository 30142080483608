.accordion-content-item-finan{
    display: flex;
    padding-bottom: 20px;
}

.accordion-content-item-finan p:nth-child(2){
    width: 25%;
    padding-right: 20px;
    font-weight: 600;
    padding-left: 20px !important;
} 

.accordion-content-item-finan p:nth-child(3){
    color: #000;
    padding-left: 20px;
}

.accordion-content-item-extrato{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
}

.accordion-content-item-extrato div{
    min-height: 40px;
    border-left: 4px solid #999;
    transition: border .5s;
}

.accordion-content-item-extrato:hover div{
    border-left: 4px solid #4677ff;
}

.accordion-content-item-extrato:hover .dinheiro{
    font-size: 16px;
    color: #000;
    font-weight: 700;
}

.accordion-content-item-extrato div p{
    padding-left: 10px;
}

.accordion-content-item-extrato div p:nth-child(1){
    font-weight: 700;
    color: #000;
    margin-bottom: 5px;
}

.accordion-content-item-extrato .dinheiro{
    font-size: 16px;
    color: #888;
    transition: all .5s;
}