
div.selecaoVistoria .campo-input label{
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}


div.selecaoVistoria .campo-input{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

div.selecaoVistoria .campo-input-sel{
    display: flex;
    justify-content: space-between;
    padding-right: 10px;

}

div.selecaoVistoria .campo-input-sel select{
    background-color: rgba(0,0,0,0);
    border: none;
    color: #001292;
    font-weight: bold;
    font-size: 13px;
    text-transform: uppercase;
}

div.selecaoVistoria {
    width: 90%;
    background: #fff;
    height: auto;
}

div.selecaoVistoria header{
    background: #4677FF;
    padding: 0 .75rem;
    height: 4rem;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    justify-content: space-between;
}

div.selecaoVistoria header form, div.selecaoVistoria header section{
    width: 100%;
}

div.selecaoVistoria header form div.campo-input {
    display: flex;
    flex-direction: row;
    align-items: center;
}

div.gridlay{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

div.gridlay div.item{
    height: 3rem;
    padding: .5rem;
    border-bottom: 1px solid #e9e9e9;
    border-top: 0.5px solid #e9e9e9;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

div.gridlay div.item-ok{
    background: #c9d6fa;
    color: #4677FF;
}

div.com-desc-itens{
    display: flex;
    justify-content: center;
    padding: .75rem;
}

div.com-desc-itens form .desc-item-cam{
    width: 100%;
    height: 50px;
}

div.com-desc-itens form .btn-cam{
    width: 100%;
    height: 100%;
    padding: 5px 10px;
    margin-top: 1em;
    border: 2px solid #818182;
    color: #818182;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    transition: all .5s;
}

div.com-desc-itens form input[type='file']{
    display: none;
}

div.com-desc-itens form {
    display: flex;
    flex-direction: column;
    width: 60%;
}

div.com-desc-itens form .desc-item-desc{
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

div.com-desc-itens form .desc-item{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50px;
    border: 2px solid #858585;
    border-radius: 5px;
}


div.com-desc-itens form .desc-item-desc textarea{
    width: 100%;
    height: 200px;
    resize: none;
    margin-top: 10px;
    padding:10px;
    border: 2px solid #818182;
    border-radius: 5px;
}

div.com-desc-itens form .desc-item-desc label{
    color: #818182;
}

div.com-desc-itens form .desc-item input{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0);
    border: none;
    padding-left: 10px;
    color: #858585;
    font-size: 15px;
}

div.com-desc-itens form .desc-item select{
    width: calc(100% - 10px);
    height: 100%;
    background-color: rgba(0,0,0,0);
    border: none;
    padding-left: 10px;
    color: #858585;
    font-size: 15px;
}

div.com-desc-itens form #imagem {
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    display: none;
  }

  div.com-desc-itens form  #list{
    width: 100%;
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

div.com-desc-itens form  #list .seele{
    margin: .25rem;
    width: 3.5rem;
    height: 3.5rem;
    position: relative;
}

div.com-desc-itens form  #list .seele:hover .opts{
    opacity: 1;
}

div.com-desc-itens form  #list .seele .opts{
    background: rgba(0,0,0,.5);
    width: 3.5rem;
    height: 3.5rem;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    opacity: 0;
    transition: .3s ease-in-out;
}

div.com-desc-itens form  #list .seele .opts i{
    flex-grow: 1;
    color: white;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
}

div.com-desc-itens form  #list img{
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 4px;
}

div.com-desc-itens form  .desc-item-desc label{
    color: #818182;
}

div.com-desc-itens form .btn-cam:hover{
    border: 2px solid #4677FF;
    color: #4677FF;
}

div.com-desc-itens form footer button{
    padding: 10px 20px;
    background-color: #4677FF;
    text-decoration: none;
    color: #fff;
    border-radius: 3px;
    width: auto;
    height: auto;
    border: none;
    font-size: 15px;
    margin-top: .5rem;
}

div.seeimg{
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color:  rgba(0,0,0,.5);
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 100;

}

div.seeimg img{
    height: auto;
    width: auto;
    max-width: 90%;
    max-height: 90%;
    z-index: 100;
}

.d-flex-center{
    display: flex;
    flex-direction: row;

}

.d-flex-center .modal{
    position: absolute;
    width: 50%;
    bottom: -80px;
    background: #E5E5E5;
    border-radius: 5px;
    display: none;
    top: initial;
    right: initial;
    left: initial;
    z-index: 1;
}

.modal-item{
    padding:10px;
    color: #757575;
    cursor: pointer;
}

.modal-item:nth-child(1){
    border-bottom: 1px solid #ccc;
}

.modal-item:hover{
    color: #444;
    font-weight: 500;
}

#cores{
    right: 0;
}

.d-flex-center{
    margin: 10px 0px;
    display: flex;
    align-items: center;
    position: relative;
}

.btn-itens{
    width: 50%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    border: 2px solid #818182;
    color: #818182;
    border-radius: 5px;
}

.btn-itens p {
    margin-bottom: 0;
}

.btn-itens i{
    margin-right: 5px;
}

.btn-itens:nth-child(1){
    margin-right: 2.5px;
}

.btn-itens:nth-child(2){
    margin-left: 2.5px;
}


div.listcarac{
    padding: .5rem;
}

div.lambdaCaracs header p{
    font-size: 120%;
    color: #4677ff;
    font-weight: 600;
}

div.lambdaCaracs .desc-item select{
    width: calc(100% - 10px);
    height: 100%;
    background-color: rgba(0,0,0,0);
    border: none;
    padding-left: 10px;
    color: #858585;
    font-size: 15px;
}

div.lambdaCaracs .desc-item input{
    border: none;
}

div.lambdaCaracs .desc-item{
    width: 100%;
    height: 50px;
    border: 2px solid #858585;
    border-radius: 5px;
}

div.listcarac p{
    border-bottom: 1px solid rgba(0,0,0,.075);
    cursor: pointer;
}

div.caracsItens{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: .5rem;
}

div.caracs{
    background-color: #e7e7e7;
    padding: .4rem;
    margin: .25rem;
    color: #656565;
    font-size: 90%;
}

div.caracs span{
    margin-left: 1.25rem;
    cursor: pointer;
}

div.paintMaker{
    position: fixed;
    top: 0;
    height: 0;
    width: 100%;
    height: 100%;
    z-index: 300;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

div.lambdaCaracs{
    background-color: white;
    width: 85%;
    height: 85%;
    overflow: auto;
    padding: .5rem;
    border-radius: 4px;
}

label.caracslabel{
    display: inline-block;
}

label.caracslabel div.caracsstyle{
    padding: .75rem;
    border-radius: 6px;
    margin: .25rem;
    border: 2px solid #4677ff;
}

label.caracslabel input:checked ~ div.caracsstyle{
    background-color: #4677ff;
    color: white;
}

div.paintMaker{
    position: fixed;
    top: 0;
    height: 0;
    width: 100%;
    height: 100%;
    z-index: 300;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

div.paintMaker div.patters{
    width: 87.5%;
    height: 87.5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.paintMaker div.smenucanvas{
    background-color: white;
}

div.paintMaker div.smenucanvas ul{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 0;
}

div.paintMaker div.smenucanvas ul li{
    list-style: none;
    line-height: 3rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

div.paintMaker div.smenucanvas ul li.saves{
    color: #0ba049;
}

div.com-sel-col.sle-out button{
    font-size: 2.75rem;
}

div.paintMaker div.smenucanvas ul li input{
    border-radius: 100%;
    width: 1.5rem;
    height: 1.5rem;
    border: none;
}

