div.highPerfil {
    width: 80%;
    background-color: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    // align-items: center;

    div.componentPerf {
        display: flex;
        flex-direction: row;
        align-items: center;

        span.estrelado {
            color: #8989f9;
        }

        img {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 100%;
            margin-right: 1rem;
        }

        p {
            font-weight: 600;
        }
    }

    div.DataPerfil {
        display: flex;
        flex-direction: column;
        margin-top: 1.25rem;

        div.dgr {
            display: flex;
            flex-direction: row;
            margin: .25rem;

            div.highData {
                flex-grow: 1;
                margin: 0 .75rem;
                // background-color: blue;
                width: 100%;

                &.mrl {
                    margin-right: 0;
                }

                &.mln {
                    margin-left: 0;
                }

                &.growMK2 {
                    width: 33%;
                }

                span {
                    color: #888;
                    font-size: .8rem;
                    padding-bottom: .25rem;
                }

                input {
                    width: 100%;
                    height: 2.3rem;
                    border-radius: 4px;
                    padding: 0 .75rem;
                    font-size: .9rem;
                    border: 1px solid #ccc;
                }

                div.collective {
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-end;
                    height: 2.3rem;

                    input {
                        pointer-events: none;
                        position: absolute;
                        appearance: none;
                        -webkit-appearance: none;
                        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
                        border: none;
                        left: 0;
                        top: 0;
                        padding: 0;
                        height: 1rem;
                        background: #f1f1f1;
                        
                        & ~ input{
                            background: none;
                        }

                        &::-webkit-slider-thumb {
                            pointer-events: all;
                            position: relative;
                            z-index: 1;
                            outline: 0;
                            -webkit-appearance: none;
                            appearance: none;
                            width: 1.25rem;
                            height: 1.25rem;
                            border: none;
                            border-radius: 100%;
                            background-color: blue;
                        }

                        &::-moz-range-thumb {
                            pointer-events: all;
                            position: relative;
                            z-index: 10;
                            -moz-appearance: none;
                            appearance: none;
                            width: 1.25rem;
                            height: 1.25rem;
                            border: none;
                            border-radius: 100%;
                            background-color: blue;
                        }
                    }
                }

                div.sameValue {
                    width: 100%;
                    height: 2.3rem;
                    // background-color: ;
                    background-color: #f1f1f1;
                    // border: 1px solid #ccc;
                    border-radius: 4px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0 .75rem;
                    font-size: .9rem;

                    &.cataclis{
                        justify-content: space-between;
                    }
                }
            }
        }
    }
}

div.divbtn{
    background-color: #4677ff;
    max-width: fit-content;
    padding: .5rem;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

img.signed{
    max-width: 256px;
    max-height: 256px;
}