.container-menu{
    width: 100%;
    height: 100%;
}

.container-menu .content-header{
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    background-color: #fff;
}

.content-header img{
    width: 150px;
}


.content-header i{
    color: #4677ff;
    font-size: 20px;
    transition: all .2s;
    cursor: pointer;
}

.content-body{
    width: 100%;
    min-height: calc(100% - 70px);
    background-color: #EFF3FE;
    padding: 20px 0px;
    display: flex;
    justify-content: center;
}

.content-menu{
    max-width: 250px;
    height: 100%;
    background-color: #fff;
    position: fixed;
    left: -250px;
    top: 0;
    -webkit-box-shadow: 5px 0px 32px -9px rgba(0,0,0,0.2);
    -moz-box-shadow: 5px 0px 32px -9px rgba(0,0,0,0.2);
    box-shadow: 5px 0px 32px -9px rgba(0,0,0,0.2);
    opacity: 1;
    overflow: hidden;
    transition: .5s ease-in-out;
    z-index: 20;

}

.content-menu.show{
    left: 0 !important;
}

.hide{
    opacity: 0;
    max-width: 0;
    pointer-events: none;
}

.content-menu .header{
    width: 100%;
    height: 70px;
    padding-left: 20px;
    display: flex;
    align-items: center;
}

.content-menu .header img{
    width: 70%;
}

.content-menu .perfil{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 0px;
    margin-bottom: 30px;
}

.perfil .perfil-foto{
    width: 100px;
    height: 100px;
    background: #919192 url('https://source.unsplash.com/random') no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
}

.perfil p{
    margin-top: 10px ;
    font-size: 12px;
}

.menu-item{
    width: 100%;
    height: 25px;
    padding-left: 20px;
    margin: 20px 0px;
}

.menu-item i{
    color: #919192;
    padding-right: 10px;
}

.menu-item a{
    text-decoration: none;
    color: #919192;
    cursor: pointer;
}


.menu-item a:hover{
    color:#4577ff;
    font-weight: 600;
}

.menu-item:hover i{
    color:#4577ff;
}

div.overlay{
    width: 100%;
    position: fixed;
    height: 100%;
    top: 0;
    z-index: 120;
    background-color: rgba(0,0,0,0);
    transition: .3s ease-in-out;
}

div.overlay.hide{
    display: none;
}

div.overlay.colored{
    background-color: rgba(0,0,0,0.5);

}

.aNormal{
    text-decoration: none;
    color: black;
}

@media (max-width: 300px){
    .content-menu{
        width: 70%;
    }
}